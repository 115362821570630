<template>
  <div class="store-manage">
    <p>订单管理</p>

    <a-row type="flex" align="middle">
      <!-- <a-col :span="2" style="text-align: center">
        <span>订单状态：</span>
      </a-col>
      <a-col :span="20">
        <a-radio-group default-value="a" button-style="solid">
            <a-radio-button value="a">
                全部
            </a-radio-button>
            <a-radio-button value="b">
                待付款
            </a-radio-button>
            <a-radio-button value="c">
                部分付款
            </a-radio-button>
            <a-radio-button value="d">
                已完成
            </a-radio-button>
            <a-radio-button value="e">
                已取消
            </a-radio-button>
        </a-radio-group>
      </a-col> -->
      <a-col :span="24">
        订单日期：
        <a-range-picker format="YYYY-MM-DD" @change="pickerChange"/>
      </a-col>
      <a-col :span="24">
        搜索：
        <a-input-search style="width: 420px" v-model="form.searchText" placeholder="请输入买家名称" @search="form.pageNum = 1;getList()" />
      </a-col>
      <a-col :span="24">
        <a-table :data-source="data" size="middle" :columns="stroeColumns" :rowKey="(record,index)=>{return index}" :loading="loading" @change="handleTableChange" 
          :pagination="{
          size: 'default',
          total: total,
          current: form.pageNum,
          pageSize: form.pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
          showTotal: total => `共有 ${total} 条数据`,
          showQuickJumper: true,
          onChange: (pageNum) => form.pageNum = pageNum }">
          <template slot="finish" slot-scope="text,record">
            <div>合同：{{ record.contractPro }}</div>
            <div>金额：{{ record.paymentPro }}</div>
            <div>物流：{{ record.logisticsPro }}</div>
          </template>
          <template slot="orderId" slot-scope="text">
            <router-link class="id-primary" :to="'/order-details?id=' + text">{{text}}</router-link>
          </template>
          <template slot="taxableAmount" slot-scope="text">
            <span>{{text.toFixed(2)}}元</span>
          </template>
          <template slot="invoiceStatus" slot-scope="text">
            <a-badge :status="text | color" :text="text | type"></a-badge>
          </template>
          <template slot="vatTaxAmount" slot-scope="text">
            <span>{{text.toFixed(2)}}元</span>
          </template>
          <template slot="quantity" slot-scope="text,record">
            <span>{{text}}{{record.unitsMeasure | unit}}</span>
          </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { orderList } from "@/api/order";
import { type } from "@/utils/constArr";
const invoiceState = {
  WAIT_APPLY: '未申请',
  WAIT_AUDIT: '待审核',
  REFUSED: '已拒绝',
  REVIEWED: '审核通过',
  PROCESSING: '开票中',
  WAIT_INVOICE: '待开票',
  INVOICED: '已开票'
}
const color = {
  WAIT_APPLY: 'default',
  WAIT_AUDIT: 'warning',
  REFUSED: 'error',
  REVIEWED: 'success',
  PROCESSING: 'processing',
  WAIT_INVOICE: 'warning',
  INVOICED: 'success'
}
const stroeColumns = [
  {
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    title: '买家',
    dataIndex: 'purchaserCommercialName',
    align: 'left',
  },
  {
    title: '订单总价（不含增值税）',
    dataIndex: 'taxableAmount',
    scopedSlots: { customRender: 'taxableAmount' },
    align: 'right',
  },
  {
    title: '增值税',
    dataIndex: 'vatTaxAmount',
    scopedSlots: { customRender: 'vatTaxAmount' },
    align: 'right',
  },
  {
    title: '数量',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' },
    align: 'right',
  },
  {
    title: '完成度',
    scopedSlots: { customRender: 'finish' },
    align: 'right',
  },
  {
    title: '开票状态',
    dataIndex: 'invoiceStatus',
    scopedSlots: { customRender: 'invoiceStatus' }
  },
  {
    title: '下单日期',
    dataIndex: 'createdTime',
    align: 'left',
  }
]
export default {
  data() {
    return {
      data: [],
      loading: false,
      stroeColumns,
      form: {
        pageNum: 1,
        pageSize: 10,
        searchText: undefined,
        invoiceStatusArray: undefined,
        logisticsStatusArray: undefined,
        beginTime: undefined,
        endTime: undefined,
        supplierId: this.$route.query.entityId
      },
      total: 0
    }
  },
  mounted() {
    this.getList()
  },
  filters: {
      unit:(val)=>type[val],
      type: (val) => invoiceState[val],
      color: (val) => color[val]
  },
  methods: {
    getList() {
      this.loading = true
      orderList(this.form).then(res => {
        console.log(res)
        this.loading = false
        if (res.code === 200) {
          this.data = res.data.list
          this.total = res.data.total
        } else if (res.code === 405) {
          this.data = []
          this.form.pageNum = 1
          this.form.pageSize = 10
          this.total = 0
        }
      })
    },
    pickerChange(e,date) {
      this.form.beginTime = date[0] || null
      this.form.endTime = date[1] || null
      this.form.pageNum = 1
      this.getList()
    },
    handleTableChange (e) {
        this.form.pageNum = e.current
        this.form.pageSize = e.pageSize
        this.getList()
    },
  }
}
</script>

<style lang="less" scoped>
.store-manage {
  padding: 20px;
  background: #fff;
  min-height: 750px;
  font-size: 14px;
  /deep/ .ant-col {
    margin-bottom: 14px;
  }
  p {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 650;
    margin-bottom: 24px;
  }
  /deep/ .ant-table-wrapper{
    margin-top: 24px;
  }
}
</style>