var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"store-manage"},[_c('p',[_vm._v("订单管理")]),_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{attrs:{"span":24}},[_vm._v(" 订单日期： "),_c('a-range-picker',{attrs:{"format":"YYYY-MM-DD"},on:{"change":_vm.pickerChange}})],1),_c('a-col',{attrs:{"span":24}},[_vm._v(" 搜索： "),_c('a-input-search',{staticStyle:{"width":"420px"},attrs:{"placeholder":"请输入买家名称"},on:{"search":function($event){_vm.form.pageNum = 1;_vm.getList()}},model:{value:(_vm.form.searchText),callback:function ($$v) {_vm.$set(_vm.form, "searchText", $$v)},expression:"form.searchText"}})],1),_c('a-col',{attrs:{"span":24}},[_c('a-table',{attrs:{"data-source":_vm.data,"size":"middle","columns":_vm.stroeColumns,"rowKey":function (record,index){return index},"loading":_vm.loading,"pagination":{
        size: 'default',
        total: _vm.total,
        current: _vm.form.pageNum,
        pageSize: _vm.form.pageSize,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
        showTotal: function (total) { return ("共有 " + total + " 条数据"); },
        showQuickJumper: true,
        onChange: function (pageNum) { return _vm.form.pageNum = pageNum; } }},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"finish",fn:function(text,record){return [_c('div',[_vm._v("合同："+_vm._s(record.contractPro))]),_c('div',[_vm._v("金额："+_vm._s(record.paymentPro))]),_c('div',[_vm._v("物流："+_vm._s(record.logisticsPro))])]}},{key:"orderId",fn:function(text){return [_c('router-link',{staticClass:"id-primary",attrs:{"to":'/order-details?id=' + text}},[_vm._v(_vm._s(text))])]}},{key:"taxableAmount",fn:function(text){return [_c('span',[_vm._v(_vm._s(text.toFixed(2))+"元")])]}},{key:"invoiceStatus",fn:function(text){return [_c('a-badge',{attrs:{"status":_vm._f("color")(text),"text":_vm._f("type")(text)}})]}},{key:"vatTaxAmount",fn:function(text){return [_c('span',[_vm._v(_vm._s(text.toFixed(2))+"元")])]}},{key:"quantity",fn:function(text,record){return [_c('span',[_vm._v(_vm._s(text)+_vm._s(_vm._f("unit")(record.unitsMeasure)))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }